// GENERATED BY process-svg DO NOT EDIT
import { GeneratedIcon } from './Icon'
import { GeneratedIconProps } from './Icon/types'
// @ts-ignore
import Svg from './svg/icon-search.svg?react'

export const IconSearch = (props: GeneratedIconProps) => (
  <GeneratedIcon {...props}>
    <Svg preserveAspectRatio="xMidYMid meet" />
  </GeneratedIcon>
)

IconSearch.displayName = 'IconSearch'
