import { ThemeColor } from 'styles/theme'

export const iconSizes = {
  xLarge: 64,
  large: 48,
  medium: 40,
  small: 24,
  xSmall: 16,
}

export type IconSizes = keyof typeof iconSizes

export type GeneratedIconProps = {
  color?: ThemeColor
  size?: IconSizes
  className?: string
}
