import { IconLight } from '../../components/icons'

import {
  BaseCheckboxInput,
  BaseCheckboxLabel,
  CheckboxInputContainer,
  CheckIcon,
} from './styles'
import type { CheckboxProps } from './types'

export const RadioInput = ({
  checked,
  ...attrs
}: CheckboxProps): JSX.Element => (
  <CheckboxInputContainer>
    <BaseCheckboxInput {...attrs} checked={checked} />
    <CheckIcon />
  </CheckboxInputContainer>
)

export const Switch = ({ children, ...attrs }: CheckboxProps): JSX.Element => (
  <BaseCheckboxLabel>
    {children}
    <RadioInput {...attrs} />
  </BaseCheckboxLabel>
)
