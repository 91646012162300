import { FC } from 'react'

import styled from 'styled-components'

import { space } from 'styles/utils'

import { FlexGroup } from './FlexGroup'

export const Wrapper = styled(FlexGroup).attrs({
  justifyContent: 'center',
  direction: 'column',
  gap: 2,
  alignItems: 'center',
  forwardedAs: 'section',
})`
  flex: 1;
  padding: ${space(14)} 0;
  > div {
    width: 100%;
  }
`

export const FillViewport: FC = ({ children }) => (
  <Wrapper>
    <div>{children}</div>
  </Wrapper>
)
