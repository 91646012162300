import { ElementType, FC } from 'react'

import { AppPage } from './styles'

export const Page: FC<{
  as?: ElementType
}> = ({ children, as, ...rest }) => (
  <AppPage as={as} {...rest}>
    {children}
  </AppPage>
)
