import React, {
  Children,
  ReactElement,
  Suspense,
  isValidElement,
  useEffect,
  useRef,
  useState,
} from 'react'

import { OrbitControls } from '@react-three/drei'
import { Canvas, useThree } from '@react-three/fiber'
import styled from 'styled-components'
import type { OrbitControls as OrbitControlsImpl } from 'three-stdlib'
import { useSpring } from 'react-spring'

const Container = styled.div`
  height: 380px;
  left: 0;
  position: relative;
  top: 0;
  width: 100%;
  z-index: 0;
`

type ModelViewerProps = {
  children: React.ReactNode
  showing: boolean
}

const CAMERA_CONFIG = {
  fov: 5,
  zoom: 0.8,
  near: 0.1,
  far: 1000,
}

const azimuthalAngleStart = Math.PI * 0.8
const azimuthalAngleEnd = Math.PI

const polarAngleStart = Math.PI / 2.5
const polarAngleEnd = 0

const SetClearColor = ({ c }: { c: string }) => {
  const { gl } = useThree()
  gl.setClearColor(c, 1)
  return null
}

const CameraSetup = ({ showing }: { showing: boolean }) => {
  const { camera } = useThree()
  const controlsRef = useRef<OrbitControlsImpl>(null)
  const [animate, setAnimate] = useState<boolean>(false)
  // Define the spring animation for azimuthal and polar angles
  const [styles, api] = useSpring(() => ({
    azimuthalAngle: azimuthalAngleStart,
    polarAngle: polarAngleStart,
    config: { duration: 1000 },
    loop: false,
    reset: true,
    onStart: () => {
      if (!controlsRef?.current) return
      controlsRef.current.enabled = false
    },
    onRest: () => {
      if (!controlsRef?.current) return
      controlsRef.current.enabled = true
    },
  }))

  const resetPosition = () => {
    camera.position.set(0, 0, -3)
    if (controlsRef.current) {
      controlsRef.current.setAzimuthalAngle(styles.azimuthalAngle.get())
      controlsRef.current.setPolarAngle(styles.polarAngle.get())
      controlsRef.current.target.set(0, 0, 0)
      controlsRef.current.update()
    }
  }

  // Update Obit controls
  useEffect(() => {
    if (!controlsRef.current || !animate) return
    controlsRef.current.setAzimuthalAngle(styles.azimuthalAngle.get())
    controlsRef.current.setPolarAngle(styles.polarAngle.get())
    controlsRef.current.update()
  }, [styles.azimuthalAngle, styles.polarAngle, animate])

  useEffect(() => {
    camera.position.set(0, 0, -3)
    if (controlsRef.current) {
      controlsRef.current.target.set(0, 0, 0)
      controlsRef.current.setAzimuthalAngle(styles.azimuthalAngle.get())
      controlsRef.current.setPolarAngle(styles.polarAngle.get())
    }
  }, [camera])

  const startAnimation = () => {
    api.start({
      from: {
        azimuthalAngle: azimuthalAngleStart,
        polarAngle: polarAngleStart,
      },
      to: { azimuthalAngle: azimuthalAngleEnd, polarAngle: polarAngleEnd },
    })
  }

  useEffect(() => {
    startAnimation()
  }, [])

  useEffect(() => {
    resetPosition()

    if (!showing) {
      setAnimate(false)
    } else {
      setAnimate(true)
      startAnimation()
    }
  }, [showing])

  return (
    <OrbitControls autoRotate={false} ref={controlsRef} enableZoom={false} />
  )
}

const Renderer = ({ children }: Omit<ModelViewerProps, 'showing'>) => {
  const { gl } = useThree()
  return (
    <>
      {Children.map(children, (child) => {
        if (isValidElement(child)) {
          return React.cloneElement(child as ReactElement, {
            renderer: gl,
          })
        }
        return child
      })}
    </>
  )
}

export const ModelViewer = ({ children, showing }: ModelViewerProps) => {
  return (
    <Container>
      <Canvas
        shadows
        dpr={[1, 3]}
        camera={CAMERA_CONFIG}
        gl={{ preserveDrawingBuffer: true }}
      >
        <CameraSetup showing={showing} />
        <SetClearColor c="#1c1c1c" />
        {/* <Stage environment={null} shadows={false} adjustCamera scale={1}> */}
        <ambientLight intensity={1} />
        <Suspense fallback={null}>
          <Renderer>{children}</Renderer>
        </Suspense>
        {/* </Stage> */}
      </Canvas>
    </Container>
  )
}
