import { useEffect, useState, ReactNode, Suspense } from 'react'

import { ErrorBoundary } from 'react-error-boundary'
import { Route, Routes, useLocation, RouteProps } from 'react-router-dom'
import { animated, useTransition } from 'react-spring'
import styled from 'styled-components'

import { ErrorFallback } from 'components/ErrorFallback'
import { useAppContext } from 'context'
import { MAX_WIDTH } from 'styles/theme'
import { color } from 'styles/utils'

const View = styled.main`
  --header-height: 84px;
  margin: 0 auto;
  max-width: ${MAX_WIDTH};
  width: 100%;

  &:before {
    background: ${color('background')};
    content: '';
    height: 200px;
    position: fixed;
    top: -200px;
    width: 100%;
    z-index: 2;
  }

  & > div {
    position: relative;
    z-index: 0;
  }
  & > div:not(:first-of-type) {
    left: 0;
    margin: 0 auto;
    max-width: ${MAX_WIDTH};
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }
`

const isHome = (path: string) => path === '/'

const fromTransition = (path: string, initial: boolean) => {
  const isHomePath = isHome(path)
  let transform = 'translate3d(0%,0,0)'
  if (!initial && isHomePath) transform = 'translate3d(-100%,0,0)'
  if (!initial && !isHomePath) transform = 'translate3d(0,0, 0)'
  return {
    transform,
  }
}

const leaveTransition = (path: string) => {
  const isHomePath = isHome(path)
  let transform = 'translate3d(0,0,0)'
  if (!isHomePath) transform = 'translate3d(-100%,0, 0)'
  return {
    zIndex: isHomePath ? 0 : 2,
    transform,
  }
}

// const config = (path: string) => {
//   // const isHomePath = isHome(path)

//   return {
//     mass: 10,
//     tension: 380,
//     friction: 120,
//     clamp: true,
//   }

//   // if (!isHomePath)
//   //   return {
//   //     mass: 10,
//   //     tension: 380,
//   //     friction: 120,
//   //     clamp: true,
//   //   }
//   // return {
//   //   mass: 100,
//   //   tension: 300,
//   //   friction: 220,
//   //   clamp: true,
//   // }
// }

const RoutesComponent = ({
  routes,
  children,
}: {
  children: ReactNode
  routes: Array<
    RouteProps & {
      i18nKey?: string
      nested?: RouteProps
    }
  >
}) => {
  const location = useLocation()
  const [initialRoute, setInitialRoute] = useState<boolean>(true)
  const { setSelectedBook } = useAppContext()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  useEffect(() => {
    setInitialRoute(false)
  }, [])

  // Used to ensure animation overflows are hidden.
  const [animating, setAnimating] = useState(false)
  useEffect(() => {
    if (animating) {
      document.body.classList.add('animation--overflow--hidden')
    } else {
      document.body.classList.remove('animation--overflow--hidden')
    }
  }, [animating])

  const transitions = useTransition(location, {
    from: fromTransition(location.pathname, initialRoute),
    enter: {
      transform: 'translate3d(0%,0,0)',
    },
    leave: leaveTransition(location.pathname),
    config: {
      mass: 10,
      tension: 380,
      friction: 120,
      clamp: true,
    },
    keys: location.pathname, // Prevent transitions on query params
    onStart: () => {
      document.body.classList.add('animation--overflow--hidden')
      setAnimating(true)
    },
    onRest() {
      setAnimating(false)
      if (isHome(location.pathname)) {
        setSelectedBook(null)
      }
    },
  })

  return (
    <View>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Suspense fallback={<div className="loading-screen" />}>
          {transitions((props, item) => (
            <animated.div style={props} key={item.key}>
              <Routes location={item}>
                {children}
                {routes.map(
                  ({ path, element: Element, nested: nestedRoute }) => (
                    <Route
                      key={Array.isArray(path) ? path[0] : path}
                      path={path}
                      element={Element}
                    >
                      {nestedRoute && (
                        <Route
                          key={
                            Array.isArray(nestedRoute.path)
                              ? nestedRoute.path[0]
                              : nestedRoute.path
                          }
                          path={nestedRoute.path}
                          element={nestedRoute.element}
                        />
                      )}
                    </Route>
                  )
                )}
              </Routes>
            </animated.div>
          ))}
        </Suspense>
      </ErrorBoundary>
    </View>
  )
}

export default RoutesComponent
