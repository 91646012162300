import styled, { keyframes } from 'styled-components'

import { color, space } from '../../styles/utils'

import type { CheckboxProps, LabelProps } from './types'

const zoomIn = keyframes`
  from {
    opacity: 0.75;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
`

export const CheckIcon = styled.span`
  position: absolute;
  top: ${space(1)};
  left: ${space(1)};
  width: ${space(4)};
  height: ${space(4)};
  border-radius: 100%;
  background-color: ${color('foreground')};
  transform-origin: center;
  transition: 0.2s;
`

export const CheckboxInputContainer = styled.div`
  position: relative;
  width: ${space(6)};
  height: ${space(6)};
  width: ${space(12)};

  /* .icon {
    position: absolute;
    top: 0;
    left: 0;

    transform-origin: center;

    animation: ${zoomIn} 0.25s cubic-bezier(0.91, 0.09, 0.71, 1);
  } */
`

export const BaseCheckboxInput = styled.input.attrs<CheckboxProps>({
  type: 'checkbox',
})`
  appearance: none;

  width: inherit;
  height: inherit;

  border: 2px solid ${color('foreground')};
  border-radius: ${space(6)};

  transition: 0.2s all linear;

  &:after {
    content: '';
    position: absolute;
    inset: ${space(1)};
    border-radius: ${space(6)};

    transition: 200ms;
    background-color: transparent;
  }

  &:checked {
    border-color: ${color('accent')};

    &:after {
      background-color: ${color('accent')};
    }
    & + ${CheckIcon} {
      left: calc(100% - 4px);
      transform: translateX(-100%);
    }
  }
`

export const BaseCheckboxLabel = styled.label<LabelProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${space(4)} 0;
  border-bottom: 1px solid ${color('foreground')}; ;
`
