import { ConfigOptions } from 'utils/settings'

export default {
  // Default language of the UI if we can't detect their language
  defaultUILanguage: 'en',
  uiLanguages: ['en'],
  // Default language for content
  defaultContentLanguage: 'en',
  contentLanguages: ['en'],
} as ConfigOptions
