import get from 'lodash/get'

import { BASE_FONT_SIZE, SPACING_MULTIPLIER } from './constants'
import {
  ThemeInterface,
  ThemeInterfaceKeys,
  ThemeColor,
  ThemeFontFamily,
  ThemeFontWeight,
  ThemeFontSize,
} from './theme'

export const pxToRem = (px: number): string => `${px / BASE_FONT_SIZE}rem`

export const space = (
  num: number = 1,
  suffix: boolean = true
): string | number => {
  if (num % 1 !== 0) {
    throw new Error('An integer must be passed.')
  }
  return suffix ? `${num * SPACING_MULTIPLIER}px` : num * SPACING_MULTIPLIER
}

type Props = { theme: ThemeInterface }

export const color =
  (themeColor: ThemeColor) =>
  ({ theme }: Props): string =>
    theme.color[themeColor] as string

export const font =
  (fontFamily: ThemeFontFamily) =>
  ({ theme }: Props): string =>
    theme.font.family[fontFamily]

export const fontWeight =
  (themeFontWeight: ThemeFontWeight) =>
  ({ theme }: Props): number =>
    theme.font.weight[themeFontWeight]

export const fontSize =
  (themeFontSize: ThemeFontSize) =>
  ({ theme }: Props): string =>
    theme.font.size[themeFontSize]

export const theme = (keys: ThemeInterfaceKeys) => (props: any) =>
  get(props, `theme.${keys}`)
