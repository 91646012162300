import styled, { css } from 'styled-components'

import { FlexGroup } from 'components/FlexGroup'
import { MAX_WIDTH } from 'styles/theme'
import { color, space } from 'styles/utils'

export const SearchLoading = styled.div`
  align-items: center;
  color: ${color('secondaryText')};
  display: flex;
  justify-content: center;
  margin: ${space(14)} auto 0;
  max-width: 600px;
  padding: ${space(4)};
  padding: 20px 0;
  padding-top: ${space(2)};
  text-align: center;
  width: 100%;
`

export const AppFrame = styled.div`
  background: ${color('background')};
  display: flex;
  flex-direction: column;
  /* max-width: ${MAX_WIDTH}; */
  /* height: 100%; */
  min-height: var(--vh);
  width: 100%;
  &.offset {
    background: transparent;
    margin-top: 380px;
    min-height: calc(var(--vh) - 380px);
  }
`

export const AppBody = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0 auto;
  /* max-width: ${MAX_WIDTH}; */
  position: relative;
  width: 100%;
`

const SEARCH_HEIGHT = 110
export const AppPage = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: ${SEARCH_HEIGHT}px 0 0;
`

export const AppHeader = styled.div<{ secondary: boolean }>`
  align-items: center;
  background: ${color('background')};
  color: ${color('primaryText')};
  display: flex;
  height: var(--header-height);
  padding: ${space(3)} ${space(4)};
  pointer-events: none;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 50;

  &.offset {
    top: -380px;
  }

  > * {
    pointer-events: auto;
  }

  button {
    padding-left: 0;
  }

  h1 {
    color: inherit;
  }

  img {
    margin: 0 auto;
    max-width: 100%;
  }

  ${({ secondary }) =>
    secondary &&
    css`
      align-items: start;
      background: none;
      border: none;
      mix-blend-mode: exclusion;
      padding: ${space(5)} ${space(4)};
      position: absolute;
      h1 {
        color: inherit;
        margin-bottom: 0;
        margin-left: ${space(9)};
        margin-right: ${space(9)};
        text-align: center;
        text-transform: initial;
        visibility: hidden;
        width: 100%;
      }
      button {
        position: absolute;
      }
    `}
`

export const LoadingFailure = styled(FlexGroup).attrs({
  direction: 'column',
  gap: 2,
  forwardedAs: 'section',
})`
  color: ${color('primaryText')};
  text-align: center;
  width: 100%;
`

export const CloseButton = styled.button`
  color: ${color('primaryText')};
  padding: ${space(3)} ${space(4)};
`
