import styled, { css } from 'styled-components'

import { space, color, font, fontWeight, fontSize } from 'styles/utils'

export const buttonStyles = css`
  align-items: center;
  background-color: ${color('accent')};
  border: 0;
  border-radius: 32px;
  color: ${color('primaryText')};
  display: flex;
  font-family: ${font('base')};
  font-size: ${fontSize('l')};
  font-weight: ${fontWeight('bold')};
  justify-content: center;
  letter-spacing: 0.06em;
  line-height: 1.2;
  min-height: 50px;
  padding: ${space(2)} ${space(8)};
  text-transform: uppercase;
  transition: 280ms all ease-in-out;

  will-change: background-color;

  &[disabled] {
    color: ${color('interactiveDisabled')};
    pointer-events: none;
  }

  &.inactive {
    background-color: #000;
    color: ${color('secondaryText')};
  }
`
export const Button = styled.button<{
  secondary?: boolean
  small?: boolean
}>`
  ${buttonStyles};
  ${({ secondary }) =>
    secondary &&
    css`
      padding: ${space(2)} 0;
      background-color: unset;
      color: ${color('interactive')};
      fill: ${color('interactive')};
      &:hover {
        background-color: unset;
        color: ${color('interactiveHover')};
        fill: ${color('interactiveHover')};
      }
    `}
  ${({ small }) =>
    small &&
    css`
      font-size: ${fontSize('xs')};
      min-height: 30px;
      padding: ${space(2)} ${space(4)};
    `}
`
