import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

export { useTranslation, Trans } from 'react-i18next'

/**
 * i18n initialisation function, uses i18next.
 *
 * @category i18n
 */
export const i18n = (
  translations: any,
  supportedLngs: string[],
  fallbackLanguages: string[] | string
) =>
  i18next
    // passes i18n down to react-i18next
    .use(initReactI18next)
    // detect user language
    .use(LanguageDetector)
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      resources: translations,
      fallbackLng: fallbackLanguages,
      supportedLngs,
      interpolation: {
        escapeValue: false, // react already safes from xss
      },
    })
