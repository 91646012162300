import styled from 'styled-components'

import { fontSize, space } from '../../styles/utils'

export const SettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${space(12)};
  width: 100%;
`

export const SettingsFooter = styled.footer`
  display: flex;
  flex-direction: row;
  gap: ${space(9)};
  font-size: ${fontSize('xs')};

  button {
    margin-left: auto;
  }
`

export const TitleBlock = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${space(4)};
  align-items: start;
`

export const DanceSprite = styled.div`
  @keyframes play {
    100% {
      background-position: -504px;
    }
  }
  animation: play 800ms steps(8) infinite;
  background-image: url('/assets/dance-sprite.png');
  filter: contrast(0) brightness(100) blur(1px);
  height: 67px;
  margin-right: ${space(3)};
  opacity: 0.6;
  width: calc(504px / 8);
  transform-origin: 0 0;
  &.pause {
    animation-play-state: paused;
  }
  transform: scale(0.6);
`
