import { PropsWithChildren } from 'react'
import classNames from 'classnames'

import { StyledIcon } from './styles'
import { GeneratedIconProps, iconSizes } from './types'

export const GeneratedIcon = ({
  size: propsSize,
  color,
  className,
  children,
  ...rest
}: PropsWithChildren<GeneratedIconProps>) => {
  const size = propsSize ? iconSizes[propsSize] : iconSizes.small
  return (
    <StyledIcon
      className={classNames('icon', className)}
      color={color}
      size={size}
      {...rest}
    >
      {children}
    </StyledIcon>
  )
}
