import { IconLight } from '../../components/icons'

import {
  BaseRadioInput,
  BaseRadioLabel,
  RadioInputContainer,
  RadioIcon,
} from './styles'
import type { RadioProps } from './types'

export const RadioInput = ({ checked, ...attrs }: RadioProps): JSX.Element => (
  <RadioInputContainer>
    {checked && <RadioIcon />}
    <BaseRadioInput {...attrs} checked={checked} />
  </RadioInputContainer>
)

/**
 * Our standard radio component.
 *
 * This component is a wrapper around the standard HTML radio input.
 * Use it for consistent styling and behavior, but if you need more customization,
 * you can use `RadioInput`, `BaseRadioInput`, and `BaseRadioLabel` components directly.
 */
export const Radio = ({ children, ...attrs }: RadioProps): JSX.Element => (
  <BaseRadioLabel>
    {children}
    <RadioInput {...attrs} />
  </BaseRadioLabel>
)
