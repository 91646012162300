import { useEffect, useState } from 'react'

import { SitesData, Sites } from 'types/bundle'

import { repeatableFetch } from './useBundle'
import getEnv from 'utils/getEnv'

export function useSites() {
  const [sites, setSites] = useState<Sites | null>(null)
  const env = getEnv()

  const fetchAndParse = async <TResponse>(url: string): Promise<TResponse> => {
    const response = await repeatableFetch(url)

    if (!response.ok) {
      const json = await response.json()
      throw json || { code: response.status, detail: response.statusText }
    }

    const json = await response.json()

    return json
  }
  const getSites = async () => {
    const data = await fetchAndParse<SitesData>(`${env.VITE_APP_API_URL}sites`)
    const parsedSites = data.map(({ book_count, name, slug }) => {
      return {
        count: book_count,
        name,
        slug,
      }
    })
    setSites(parsedSites)
  }
  useEffect(() => {
    getSites()
  }, [])

  return { sites }
}
