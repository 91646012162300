import styled, { css } from 'styled-components'

import { color, space } from 'styles/utils'

export const iconButtonStyles = css`
  appearance: none;
  background: none;
  border: 0;
  border-radius: ${space(1)};
  color: ${color('interactive')};
  font-size: 0.823rem;
  padding: ${space(2)};
  transition: 0.2s all;
  .icon + span {
    margin-top: ${space(2)};
  }

  &:hover {
    color: ${color('interactiveHover')};
  }
`

export const Button = styled.button<{ active?: boolean }>`
  ${iconButtonStyles}
  ${({ active }) =>
    active &&
    css`
      background: ${color('interactive')};
    `}
`
