/* eslint-disable no-constructor-return */

/**
 * Config objects returned by {@link useSettings}.
 *
 * @category Settings
 */
export type ConfigOptions = {
  /** The default language for the UI (may not be the content language) */
  defaultUILanguage: string
  /** Other languages for the UI */
  uiLanguages: string[]
  /** The default language for the content */
  defaultContentLanguage: string
  /** Other languages for the content */
  contentLanguages: string[]
}

type DefaultOptions = Partial<ConfigOptions>

const defaults: DefaultOptions = {}

class Settings {
  private initialized: boolean = false

  // @ts-ignore
  public options: ConfigOptions

  constructor(options: ConfigOptions) {
    if (!this.initialized) {
      this.initialized = true
      this.options = { ...defaults, ...options }
      return this
    }
    return this
  }

  public updateSettings(options: ConfigOptions) {
    this.options = {
      ...this.options,
      ...options,
    }
    return this
  }
}

let SETTINGS: Settings

/**
 * Initialise the settings for {@link useSettings}.
 *
 * @category Settings
 */
export const setupSettings = (options: ConfigOptions) => {
  if (SETTINGS) console.warn('Settings has already been defined')
  SETTINGS = new Settings(options)
}

/**
 * Return type for {@link useSettings}.
 *
 * @category Settings
 */
export type UseSettings = {
  /** Settings object. */
  settings: ConfigOptions

  /** Update the settings object */
  updateSettings: (options: ConfigOptions) => void

  /**
   * Get the translation for a given language.
   *
   * @param transMap translatable-map
   * @param lang language (defaults to the content language)
   * @template T type of the translatable-map
   */
  getTranslation: <T>(
    transMap: Record<string, T>,
    lang?: string
  ) => T | undefined
}

/**
 * A hook to retrieve the settings from the settings file.
 *
 * Initialise the settings with {@link setupSettings}.
 *
 * @category Settings
 */
export const useSettings = (): UseSettings => ({
  settings: SETTINGS.options,
  updateSettings: (options: ConfigOptions) => SETTINGS.updateSettings(options),
  getTranslation: <T>(
    transMap: Record<string, T>,
    lang: string = SETTINGS.options.defaultUILanguage
  ) => transMap[lang],
})
