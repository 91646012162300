import { memo } from 'react'

import { Page } from 'layouts/Layout/page'
import { refresh } from 'utils/refresh'
import { useTranslation } from 'utils/translation'

import { Button } from './Button'
import { FillViewport } from './FillViewport'

export const ErrorFallback = memo(() => {
  const { t } = useTranslation()
  return (
    <FillViewport>
      <Page>
        <div className="error-fallback">
          <div>
            <h2> {t('loading.failure')}</h2>
            <Button onClick={refresh}>{t('loading.try-again')}</Button>
          </div>
        </div>
      </Page>
    </FillViewport>
  )
})
