// Render a set of text values to canvas that handles text wrapping and padding.
// Works for both horizontal and vertical text rendering.
export const renderToCanvas = (
  ctx: CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D,
  values: Array<string>,
  font: string,
  centered: boolean,
  vertical: boolean,
  lineHeight: number,
  verticalOffset: number,
  padding: number,
  paddingVert: number,
  offset: number
) => {
  if (vertical) {
    ctx.save()
    ctx.translate(ctx.canvas.width / 2, ctx.canvas.height / 2)
    ctx.rotate(90 * (Math.PI / 180))
  }

  const width = vertical ? ctx.canvas.height : ctx.canvas.width
  const centeredOffset = centered ? width / 2 : padding

  ctx.textAlign = centered ? 'center' : 'left'
  ctx.textBaseline = vertical ? 'bottom' : 'top'

  let currentX = vertical ? 0 : centeredOffset
  let currentY = paddingVert + verticalOffset

  let lineText = ''

  // Max width thats text is aloud to fill
  const maxWidth = width - padding * 2 + (vertical ? 0 : centeredOffset)

  // Iterate `values` array to set appropriate canvas styles and text wrapping
  values.forEach((text) => {
    // Set the current text values font
    ctx.font = font

    // Split text to do text wrap checks
    const words = text.split(' ')

    // Iterate `words` array to set adjust positions for text wrapping
    for (let i = 0; i < words.length; i += 1) {
      const word = words[i]

      // Increase the `lineText` with one word as a `testLine` so we can check the width
      const testLine = `${lineText + word} `

      // Get `testLine` width
      const metrics = ctx.measureText(testLine)
      const testLineWidth = metrics.width

      // Check if the `testLine` is to long.
      if (currentX + testLineWidth > maxWidth) {
        // Fill text with the current `lineText`
        ctx.fillText(lineText, currentX + offset, currentY + offset)

        // Restart the `lineText` and populate with the word that made the current line to long.
        lineText = `${word} `

        // Reset 'currentX' position to starting of line
        currentX = vertical ? 0 : centeredOffset

        // Increase 'currentY' position to move down to next line
        currentY += lineHeight
      } else {
        // Update the `lineText` to `testLine` so it includes the new word
        lineText = testLine
      }
    }
  })

  // Fill the remaining `lineText` as it is the final line
  if (lineText.length) {
    ctx.fillText(lineText, currentX + offset, currentY + offset)
  }

  if (vertical) {
    ctx.restore()
  }
}
