import { FC, ButtonHTMLAttributes } from 'react'

import { FlexGroup } from 'components/FlexGroup'
import { IconType } from 'components/icons'
import { IconSizes } from 'components/icons/Icon/types'

import { Button as StyledButton } from './styles'

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  secondary?: boolean
  icon?: IconType
  iconSize?: IconSizes
  iconAlign?: 'left' | 'right'
  small?: boolean
}

export const Button: FC<ButtonProps> = ({
  children,
  secondary,
  onClick,
  small = false,
  disabled,
  icon: Icon,
  iconSize = 'medium',
  iconAlign = 'right',
  ...rest
}) => (
  <StyledButton
    onClick={onClick}
    disabled={disabled}
    secondary={secondary}
    small={small}
    {...rest}
  >
    <FlexGroup gap={2}>
      {Icon && iconAlign === 'left' ? <Icon size={iconSize} /> : null}
      {children}
      {Icon && iconAlign === 'right' ? <Icon size={iconSize} /> : null}
    </FlexGroup>
  </StyledButton>
)
