import styled, { keyframes } from 'styled-components'

import { color, space } from '../../styles/utils'

import type { RadioProps, LabelProps } from './types'

const zoomIn = keyframes`
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
`

export const RadioInputContainer = styled.div`
  position: relative;
  width: ${space(6)};
  height: ${space(6)};

  /* .icon {
    position: absolute;
    top: 0;
    left: 0;

    transform-origin: center;

    animation: ${zoomIn} 0.25s cubic-bezier(0.91, 0.09, 0.71, 1);
  } */
`

export const BaseRadioInput = styled.input.attrs<RadioProps>({
  type: 'radio',
})`
  appearance: none;

  width: inherit;
  height: inherit;

  border: 2px solid ${color('foreground')};
  border-radius: 100%;

  transition: 0.2s all linear;

  &:checked {
    border-color: ${color('accent')};
  }
`

export const BaseRadioLabel = styled.label<LabelProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: ${space(4)} 0;
  border-bottom: 1px solid ${color('foreground')}; ;
`

export const RadioIcon = styled.span`
  position: absolute;
  top: ${space(1)};
  left: ${space(1)};
  width: ${space(4)};
  height: ${space(4)};
  border-radius: 100%;
  background-color: ${color('accent')};

  transform-origin: center;
  animation: ${zoomIn} 0.25s cubic-bezier(0.91, 0.09, 0.71, 1);
`
