import Color from 'color'

import { BASE_FONT_SIZE } from './constants'

export enum Mode {
  LIGHT = 'light',
  DARK = 'dark',
}

export interface IVariant {
  mode: Mode
  background: string
  foreground: string
  tertiary: string
  accent: string
  border: string
  panel: string
  interactiveColor: string
  interactiveHover: string
  primaryTextColor: string
  secondaryTextColor: string
  // tertiaryTextColor: string
}
const defaultVariant: IVariant = {
  mode: Mode.DARK,
  background: '#1C1C1C', // BLACK GREY
  foreground: '#FFFFFF', // WHITE
  tertiary: '#282828', // DARK GRAY
  accent: '#FF1E88', // Mona Hot Pink
  border: '#6C6C6C',
  panel: '#373737',
  interactiveColor: '#FFFFFF', // WHITE
  interactiveHover: '#4F4F4F', // GRAY
  primaryTextColor: '#FFFFFF', // WHITE
  secondaryTextColor: '#1C1C1C', // BLACK GREY
  // tertiaryTextColor: '#727272', // LIGHT GRAY
}

// Greys
// D9D9D9 // ligh
// 5B5B5B mid & Top Border
// 282828 // dark

const pxToRem = (px: number): string => `${px / BASE_FONT_SIZE}rem`

export const generateTheme = (variant: IVariant) => {
  const {
    background,
    foreground,
    interactiveColor,
    interactiveHover,
    accent,
    secondaryTextColor,
    primaryTextColor,
    // tertiaryTextColor,
    border,
    panel,
    tertiary,
  } = variant
  return {
    mode: variant.mode,
    color: {
      background: Color(background).toString(),
      foreground: Color(foreground).toString(),
      panel: Color(panel).toString(),
      interactive: Color(interactiveColor).toString(),
      interactiveHover: Color(interactiveHover).toString(),
      interactiveDisabled: Color(secondaryTextColor).alpha(0.3).toString(),
      primaryText: Color(primaryTextColor).toString(),
      secondaryText: Color(primaryTextColor).alpha(0.66).toString(),
      border: Color(border).toString(),
      loaderBackground: Color(foreground).alpha(0.3).toString(),
      tertiary: Color(tertiary).toString(),
      accent,
      error: Color('#f4105a'),
    },
    font: {
      weight: {
        light: 300,
        base: 400,
        medium: 500,
        bold: 600,
      },
      size: {
        billboard: pxToRem(96),
        giantXL: pxToRem(56),
        giant: pxToRem(32),
        xxl: pxToRem(28),
        xl: pxToRem(22),
        l: pxToRem(20),
        m: pxToRem(17),
        base: pxToRem(16),
        s: pxToRem(14),
        xs: pxToRem(12),
        xxs: pxToRem(10),
      },
      family: {
        base: 'SF-Pro, sans-serif',
        mona: 'MonaFace, sans-serif',
        compact: 'SF-Compact, sans-serif',
      },
    },
  }
}

// 32px / 36px  xl
// 28px / 36px
// 22px / 24px
// 20px  / 28px
// 17px / 18px
// 16px / 27px
// 14px / 24px
// 12px / 27px

export const MAX_WIDTH = '1280px'

export const defaultTheme = generateTheme(defaultVariant)

export type ThemeInterface = ReturnType<typeof generateTheme>
export type ThemeColor = keyof ThemeInterface['color']
export type ThemeFontSize = keyof ThemeInterface['font']['size']
export type ThemeFontFamily = keyof ThemeInterface['font']['family']
export type ThemeFontWeight = keyof ThemeInterface['font']['weight']

export type ThemeInterfaceKeys =
  | `color.${ThemeColor}`
  | `font.size.${ThemeFontSize}`
  | `font.weight.${ThemeFontWeight}`
  | `font.family.${ThemeFontFamily}`

declare module 'styled-components' {
  export interface DefaultTheme extends ThemeInterface {} // extends the global DefaultTheme with our ThemeType.
}
