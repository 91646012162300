import styled, { css } from 'styled-components'

import { ThemeColor } from 'styles/theme'
import { color } from 'styles/utils'

export const StyledIcon = styled.span<{
  iconColor?: ThemeColor
  size: number
  className?: string
}>`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  justify-content: center;

  ${({ size }) =>
    css`
      &,
      svg {
        fill: currentColor;
        fill-rule: evenodd;
        height: ${size}px;
        width: ${size}px;
      }
    `};
`
