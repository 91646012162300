import { Workbox } from 'workbox-window'

export default function registerServiceWorker(onReady: () => void) {
  // Check if the serviceWorker Object exists in the navigator object ( means if browser supports SW )
  if ('serviceWorker' in navigator) {
    const wb = new Workbox('/sw.js')

    wb.addEventListener('installed', (event) => {
      // SW installed.  Reload for SW intercept serving SW-enabled page.
      /**
       * We have the condition — event.isUpdate because we don’t want to show
       * this message on the very first service worker installation,
       * only on the updated
       */
      onReady()

      if (event.isUpdate) {
        window.location.reload()
      }
    })

    wb.register().then((registration) => {
      console.log('registration', registration)
      navigator.serviceWorker.ready.then(() => {
        onReady()
      })

      navigator.serviceWorker.addEventListener('controllerchange', () => {
        navigator.serviceWorker.ready.then(() => {
          onReady()
        })
      })
    })
  }
}
