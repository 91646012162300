import * as React from 'react'

import styled, { css } from 'styled-components'

import { space } from 'styles/utils'

type FlexGroupStyledProps = {
  // Prefixing with $ means 'transient prop', ie won't be passed as a HTML attr
  $direction?: 'row' | 'column' | 'row-reverse'
  justifyContent?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
  alignItems?:
    | 'normal'
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'baseline'
    | 'stretch'
  alignSelf?: 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch'
  gap?: number
  className?: string
  hideOverflow?: boolean
}

export type FlexGroupProps = Omit<FlexGroupStyledProps, '$direction'> & {
  direction?: FlexGroupStyledProps['$direction']
}

/* eslint-disable max-len */
/**
 *
 * FlexGroup
 *
 * A reusable wrapper for flex patterns. The idea is to cut down on the repetition and extra weight
 * of re-defining common flex properties. Extending from this component will create a
 * unique class. Can be consumed by other styled-components using the
 * following syntax structure:
 *
 * ```
 * const Wrapper = styled(FlexGroup).attrs<FlexGroupProps>({ direction: 'column', gap: 3 })<NewComponentProps>`
 * ```
 *
 * Part of this is the use of transient props, which allow us to style without passing the
 * key down to the DOM node as a HTML attribute.
 *
 * Additionally, if you wanted to cast the new component as a different dom element, you
 * can do so using the `forwardedAs` prop like so:
 *
 * ```
 * const Wrapper = styled(FlexGroup).attrs<FlexGroupProps>({
 *  direction: 'column',
 *  gap: 3,
 *  forwardedAs: 'aside'
 * })<NewComponentProps>`
 * ```
 *
 * @category Components
 */
const Container = styled.div<FlexGroupStyledProps>`
  align-items: center;
  display: flex;
  justify-content: center;
  ${({ $direction }) =>
    $direction &&
    css`
      flex-direction: ${$direction};
    `}
  ${({ gap }) =>
    gap &&
    css`
      gap: ${space(gap)};
    `}
  ${({ justifyContent }) =>
    justifyContent &&
    justifyContent !== 'center' &&
    css`
      justify-content: ${justifyContent};
    `}
    ${({ hideOverflow }) =>
    hideOverflow &&
    css`
      overflow: hidden;
    `}
  ${({ alignItems }) =>
    alignItems &&
    alignItems !== 'center' &&
    css`
      align-items: ${alignItems};
    `}
    ${({ alignSelf }) =>
    alignSelf &&
    css`
      align-self: ${alignSelf};
    `}
`

export const FlexGroup: React.FC<FlexGroupProps> = ({
  direction,
  ...props
}) => <Container $direction={direction} {...props} />
