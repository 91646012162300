import en from './en.json'

/**
 * @internal
 */
// const global = {
//   languages: {
//     en: 'English',
//     es: 'Español',
//     ht: 'Créole Haïtien',
//     zh: '官话',
//   },
// }

export const translations = {
  en: {
    translation: { ...en },
  },
}
