// GENERATED BY process-svg DO NOT EDIT
import { GeneratedIcon } from './Icon'
import { GeneratedIconProps } from './Icon/types'
// @ts-ignore
import Svg from './svg/icon-chevron-left.svg?react'

export const IconChevronLeft = (props: GeneratedIconProps) => (
  <GeneratedIcon {...props}>
    <Svg preserveAspectRatio="xMidYMid meet" />
  </GeneratedIcon>
)

IconChevronLeft.displayName = 'IconChevronLeft'
